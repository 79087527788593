import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';
import { Timeline } from 'rsuite';

// import default style
import 'rsuite/dist/styles/rsuite-default.css'


class Achievements extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
  }

  render() {
    // description =
    //   'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <Timeline align="left">
                {this.agency.achievements.split('\n').map((value, index) => {
                  return (
                    <Timeline.Item>
                      <p>
                        {value}
                      </p>
                    </Timeline.Item>
                  );
                })}

              </Timeline>
            </div>
          </div>
        </div>

        {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
        {/* Start Breadcrump Area */}
        {/* <Breadcrumb title={'About'} /> */}
        {/* End Breadcrump Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </React.Fragment>
    );
  }
}

export default Achievements;
