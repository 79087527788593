import axios from 'axios';
import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import Header from '../component/header/HeaderFive';
import Helmet from '../component/common/Helmet';
import { FiChevronUp } from 'react-icons/fi';
import ModalVideo from 'react-modal-video';

class Posts extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
    this.posts = [];
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.getPosts();
  }


  getPosts() {
    const request = axios.get(
      `/api/v1/public/posts`,
    );
    request.then((response) => {
      this.posts = response.data.results;
      this.setState(this.state);
    });
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  getVideoId(videoUrl) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = videoUrl.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  openVideoModal(videoUrl) {
    this.setState({
      isOpen: true,
      videoId: this.getVideoId(videoUrl),
    });
  }

  render() {
    return (
      <Fragment>
        <Helmet pageTitle={this.agency.name}/>

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Content Box  */}
        <div className="rn-content-box-area rn-content-box-style--1 pb--120 bg_color--1" id="about">
          {this.posts.map((post, index) => (
            <div className="row" key={index}>
              <br/>
              <div className="row row--0 align-items-center content">
                <div className="col-lg-12 col-xl-4">
                  <div className="thumbnail position-relative">
                    <img src={post.imageUrl} alt=""/>
                    {this.getVideoId(post.postUrl) ?
                      (
                        <button className="video-popup position-top-center"
                                onClick={() => {
                                  this.openVideoModal(post.postUrl);
                                }
                                }>
                          <span className="play-icon"/>
                        </button>
                      )
                      : ''
                    }
                  </div>
                </div>
                <div className="col-lg-12 col-xl-8 mt_lg--50 mt_md--30 mt_sm--30">
                  <div className="content">
                    <h2 className="fontWeight500">{post.title}</h2>
                    <p style={{ whiteSpace: 'pre-line' }}>
                      {post.description}
                      {post.postUrl?
                        (
                      <button className="btn-transparent rn-btn-dark" onClick={() => {
                        window.open(post.postUrl, '_blank');
                      }}>
                        <span className="text">More</span>
                      </button>
                        )
                        :''}
                    </p>

                  </div>
                </div>
              </div>
            </div>
          ))}

        </div>
        {/* End Content Box  */}

        <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId={this.state.videoId}
                    onClose={() => this.setState({ isOpen: false })}/>


        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp/>
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}

export default Posts;
