import axios from 'axios';
import React, { Component } from "react";
import { Link } from 'react-router-dom';
import { FiX , FiMenu } from "react-icons/fi";


class HeaderFive extends Component {
	constructor(props) {
		super(props);
		this.menuTrigger = this.menuTrigger.bind(this);
		this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
		// this.subMetuTrigger = this.subMetuTrigger.bind(this);
		window.addEventListener('load', function () {
			console.log('All assets are loaded');
		});
		this.state = {};
		this.categories = [];
		this.getCategories();
	}
	menuTrigger() {
		document.querySelector('.header-wrapper').classList.toggle('menu-open');
	}
	CLoseMenuTrigger() {
		document.querySelector('.header-wrapper').classList.remove('menu-open');
	}
	getCategories() {
		const request = axios.get(
      '/api/v1/public/categories'
    );
		request.then((response) => {
			this.categories = response.data.results;
			this.setState(this.state);
		});
	}
	render() {
		var elements = document.querySelectorAll('.has-droupdown > a');
		for (var i in elements) {
			if (elements.hasOwnProperty(i)) {
				elements[i].onclick = function () {
					this.parentElement.querySelector('.submenu').classList.toggle('active');
					this.classList.toggle('open');
				};
			}
		}

		const { logo, color, headerPosition } = this.props;
		let logoUrl;
		if (logo === 'light') {
			logoUrl = <img src="/assets/images/logo/logo-light.png" alt="Trydo" />;
		} else if (logo === 'dark') {
			logoUrl = <img src="/assets/images/logo/logo-dark.png" alt="Trydo" />;
		} else if (logo === 'symbol-dark') {
			logoUrl = <img src="/assets/images/logo/logo-symbol-dark.png" alt="Trydo" />;
		} else if (logo === 'all-dark') {
			logoUrl = <img src="/assets/images/logo/lama_full_logo.png" alt="Trydo" />;
		} else if (logo === 'symbol-light') {
			logoUrl = <img src="/assets/images/logo/logo-symbol-light.png" alt="Trydo" />;
		} else {
			logoUrl = <img src="/assets/images/logo/logo.png" alt="Trydo" />;
		}

		return (
      <header
        className={`header-area formobile-menu ${headerPosition} ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-md-4 col-6">
                <div className="header-left">
                  <div className="logo">
                    <a href="/">{logoUrl}</a>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-8 col-6">
                <div className="header-right justify-content-end">
                  <nav className="mainmenunav d-lg-block">
                    <ul className="mainmenu">
                      <li>
                        <Link to="/">Home</Link>
                      </li>
                      <li className="has-droupdown">
                        <Link to="#">Products</Link>
                        <ul className="submenu">
                          {this.categories.map((category, index) =>
                            category.actionUrl ? (
                              <li key={index}>
                                <a
                                  href={category.actionUrl}
                                >
                                  {category.name}
                                </a>
                              </li>
                            ) : (
                            category.hasSub ? (
                              <li key={index}>
                                <Link
                                  to={`/categories/${category._id}?categoryName=${category.name}`}
                                >
                                  {category.name}
                                </Link>
                              </li>
                            ) : (
                              <li key={index}>
                                <Link
                                  to={`/subcategories/${category._id}?categoryName=${category.name}`}
                                >
                                  {category.name}
                                </Link>
                              </li>
                            ))
                          )}
                        </ul>
                      </li>
                      <li>
                        <Link to="/about">About Us</Link>
                      </li>
                      <li>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <Link to="/achievements">Our Achievements</Link>
                      </li>
                      <li>
                        <Link to="/contact">Contact</Link>
                      </li>
                    </ul>
                  </nav>

                  {/* Start Humberger Menu  */}
                  <div className="humberger-menu d-block d-lg-none pl--20">
                    <span
                      onClick={this.menuTrigger}
                      className="menutrigger text-white"
                    >
                      <FiMenu />
                    </span>
                  </div>
                  {/* End Humberger Menu  */}
                  <div className="close-menu d-block d-lg-none">
                    <span
                      onClick={this.CLoseMenuTrigger}
                      className="closeTrigger"
                    >
                      <FiX />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
    );
	}
}
export default HeaderFive;
