import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import Slider from 'react-slick';
import ServiceList from '../elements/service/ServiceList';
import { slideSlick } from '../page-demo/script';
import Header from '../component/header/HeaderFive';
import Helmet from '../component/common/Helmet';
import { FiChevronUp } from 'react-icons/fi';

const SlideList = [
	{
		textPosition: 'text-right',
		bgImage: 'bg_image--38',
		category: '',
		title: '',
		description: 'There are many variations of passages but the majority have suffered alteration.',
		buttonText: 'Contact Us',
		buttonLink: '/contact',
	},
	// {
	//     textPosition: 'text-left',
	//     bgImage: 'bg_image--31',
	//     category: '',
	//     title: 'Agency.',
	//     description: 'There are many variations of passages but the majority have suffered alteration.',
	//     buttonText: 'Contact Us',
	//     buttonLink: '/contact'
	// },
];

class LamaHome extends Component {
  constructor(props) {
    super(props);
		this.agency = this.props.agency;
    this.state = {
      isOpen: false,
    };
		this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <Fragment>
        <Helmet pageTitle={this.agency.name} />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Slider Area   */}
        <div className="slider-wrapper">
          <div className="slider-activation">
            <Slider className="rn-slick-dot dot-light" {...slideSlick}>
              {SlideList.map((value, index) => (
                <div
                  className={`slide slide-style-2 slider-box-content without-overlay d-flex align-items-center justify-content-center bg_image ${value.bgImage}`}
                  key={index}
                >
                  <div className="container">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className={`inner ${value.textPosition}`}>
                          {value.category ? <span>{value.category}</span> : ''}
                          {value.title ? (
                            <h1 className="title">{value.title}</h1>
                          ) : (
                            ''
                          )}
                          {this.agency.welcomeMessage ? (
                            <p className="description">
                              {this.agency.welcomeMessage}
                            </p>
                          ) : (
                            ''
                          )}
                          {value.buttonText ? (
                            <div className="slide-btn">
                              <a
                                className="rn-button-style--2 btn-solid"
                                href={`${value.buttonLink}`}
                              >
                                {value.buttonText}
                              </a>
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
        {/* End Slider Area   */}

        {/* Start Service Area  */}
        <div className="service-area creative-service-wrapper ptb--120 bg_color--1">
          <div className="container">
            <div className="row creative-service ">
              <div className="col-lg-12">
                <ServiceList
                  item="6"
                  column="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 text-center"
                />
              </div>
            </div>
          </div>
        </div>
        {/* End Service Area  */}

        {/* Start Counterup Area */}
        {/* <div className="counterup-area ptb--80 bg_image bg_image--17 theme-text-white" data-black-overlay="7">
					<div className="container">
						<CounterOne />
					</div>
				</div> */}
        {/* End Counterup Area */}

        {/* Start Brand Area  */}
        {/* <div className="rn-brand-area ptb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandOne branstyle="branstyle--2" />
							</div>
						</div>
					</div>
				</div> */}
        {/* End Brand Area  */}

        {/* Start call To Action  */}
        {/* <CallAction /> */}
        {/* End call To Action  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default LamaHome;
