import React, { Component } from 'react';
import Header from "../component/header/HeaderFive";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Helmet from '../component/common/Helmet';

 class Error404 extends Component {
   constructor(props) {
     super(props);
     console.log(props);
     this.agency = this.props.agency;
   }
    render() {
        return (
          <>
            <Helmet pageTitle={this.agency.name} />

            {/* Start Header Area  */}
            <Header
              headerPosition="header--static logoresize"
              logo="all-dark"
              color="color-black"
            />
            {/* End Header Area  */}
            {/* <Header
              headerPosition="header--transparent"
              color="color-white"
              logo="logo-light"
            /> */}
            {/* Start Page Error  */}
            <div className="error-page-inner bg_color--1">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="inner">
                      <h1 className="title theme-gradient">404!</h1>
                      <h3 className="sub-title">Page not found</h3>
                      <span>
                        The page you were looking for could not be found.
                      </span>
                      <div className="error-button">
                        <a className="rn-button-style--2 btn-solid" href="/">
                          Back To Homepage
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* End Page Error  */}

            {/* Start Back To Top */}
            <div className="backto-top">
              <ScrollToTop showUnder={160}>
                <FiChevronUp />
              </ScrollToTop>
            </div>
            {/* End Back To Top */}

          </>
        );
    }
}
export default Error404;
