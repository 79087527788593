import React, { Component } from 'react';
import PageHelmet from '../component/common/Helmet';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from 'react-icons/fi';
import Header from '../component/header/HeaderFive';

class About extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
  }

  render() {
    // description =
    //   'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going to use a passage of Lorem Ipsum Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which dont look even slightly believable. If you are going.';
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About" />
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />

        {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}
        {/* Start Breadcrump Area */}
        {/* <Breadcrumb title={'About'} /> */}
        {/* End Breadcrump Area */}

        {/* Start About Area  */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 ">
                <div className="col-lg-5">
                  <div className="thumbnail">
                    <img
                      className="w-100"
                      src="/assets/images/about/about-10.jpg"
                      alt="About Images"
                    />
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="about-inner inner">
                    <div className="section-title">
                      {/* <h2 className="title">{title}</h2> */}
                      <h3 className="title">
                        <strong>History and background</strong>
                      </h3>
                      {/* <p className="description">{description}</p> */}
                      <p className="description">
                        {this.agency.about.split('\n').map((value, index) => {
                          return <p>{value}</p>;
                        })}
                      </p>
                      {/* {this.agency.about} */}
                    </div>
                    {/* <div className="row mt--30">
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who we are</h3>

                          <p>
                            There are many vtions of passages of Lorem Ipsum
                            available, but the majority have suffered.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Who we are</h3>
                          <p>
                            There are many vtions of passages of Lorem Ipsum
                            available, but the majority have suffered.
                          </p>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>

              <div className="row rn-blog-details about-us-list">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p />
                  </div>
                </div>
              </div>
              <div className="row rn-blog-details about-us-list">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3 className="title">
                      <strong>Our promise</strong>
                    </h3>
                    <p>
                      {this.agency.vision.split('\n').map((value, index) => {
                        return <p>{value}</p>;
                      })}
                    </p>
                    {/*<blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer*/}
                    {/*  adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis*/}
                    {/*  natoque penatibus et magnis dis parturient montes.*/}
                    {/*</blockquote>*/}
                  </div>
                </div>
              </div>
              <div className="row rn-blog-details about-us-list">
                <div className="inner-wrapper">
                  <div className="inner">
                    <h3 className="title">
                      <strong>Values</strong>
                    </h3>
                    <p>
                      {this.agency.values.split('\n').map((value, index) => {
                        return (
                          <>
                            {value}
                            <br />
                          </>
                        );
                      })}
                    </p>
                    {/*<blockquote className="rn-blog-quote">Lorem ipsum dolor sit amet, consectetuer*/}
                    {/*  adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis*/}
                    {/*  natoque penatibus et magnis dis parturient montes.*/}
                    {/*</blockquote>*/}
                  </div>
                </div>
              </div>
              {this.agency.goals ? (
                <div className="row rn-blog-details about-us-list">
                  <div className="inner-wrapper">
                    <div className="inner">
                      <h3 className="title">
                        <strong>Goals</strong>
                      </h3>
                      <p>
                        {this.agency.goals.split('\n').map((value, index) => {
                          return <p>{value}</p>;
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
              {this.agency.productsRange ? (
                <div className="row rn-blog-details about-us-list">
                  <div className="inner-wrapper">
                    <div className="inner">
                      <h3 className="title">
                        <strong>Products Range</strong>
                      </h3>
                      <p>
                        {this.agency.productsRange
                          .split('\n')
                          .map((value, index) => {
                            return <>{value}<br/></>;
                          })}
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
        {/* End About Area  */}

        {/* Start CounterUp Area */}
        {/* <div className="rn-counterup-area pb--120 bg_color--1">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="section-title text-center">
									<h3 className="fontWeight500">Our Fun Facts</h3>
								</div>
							</div>
						</div>
						<CounterOne />
					</div>
				</div> */}
        {/* End CounterUp Area */}

        {/* Start Finding Us Area  */}
        {/* <div className="rn-finding-us-area rn-finding-us bg_color--1">
					<div className="inner">
						<div className="content-wrapper">
							<div className="content">
								<h4 className="theme-gradient">Find Your Work Now</h4>
								<p>
									It is a long established fact that a reader will be distracted by the readable
									content of a page when looking at its layout. The point of using Lorem Ipsum is
									that.
								</p>
								<a className="rn-btn btn-white" href="/about">
									Get Started
								</a>
							</div>
						</div>
						<div className="thumbnail">
							<div className="image">
								<img src="/assets/images/about/finding-us-01.png" alt="Finding Images" />
							</div>
						</div>
					</div>
				</div> */}
        {/* End Finding Us Area  */}

        {/* Start Testimonial Area */}
        {/* End Testimonial Area */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </React.Fragment>
    );
  }
}

export default About;
