import axios from 'axios';
import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import Header from '../component/header/HeaderFive';
import Helmet from '../component/common/Helmet';
import { FiChevronUp } from 'react-icons/fi';

class Categories extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
    this.categoryId = this.props.match.params.categoryId;
    // this.categoryName = this.props.match.query.categoryName;
    this.categoryName = new URLSearchParams(this.props.location.search).get(
      'categoryName'
    );
    this.category = {
      name: this.categoryName,
    };
    this.subcategories = [];
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
    this.getCategory();
    this.getSubcategories();
  }

  getCategory() {
    const request = axios.get(`/api/v1/public/categories/${this.categoryId}`);
    request.then((response) => {
      this.category = response.data;
      this.setState(this.state);
    });
  }

  getSubcategories() {
    const request = axios.get(
      `/api/v1/public/subcategories?categoryId=${this.categoryId}`
    );
    request.then((response) => {
      this.subcategories = response.data.results;
      this.setState(this.state);
    });
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <Fragment>
        <Helmet pageTitle={this.agency.name} />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Service Area */}
        <div className="service-area creative-service-wrapper pt--90 pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center pb--30">
                  <h2 className="theme-gradient">{this.category.name}</h2>
                  <p>
                    {this.category.description}
                    {/* Choose one of styles or cutomize easily your site following
                    your ideas. <br /> More demos are coming soon. */}
                  </p>
                </div>
              </div>
            </div>
            <div className="row creative-service justify-content-md-center">
              {this.subcategories.map((subcategory, index) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={index}
                >
                  <a
                    className="text-center"
                    href={subcategory.actionUrl ?? `/subcategories/${subcategory._id}?subcategoryName=${subcategory.name}`}
                  >
                    <div className="service service__style--2">
                      {/* <div className="icon">{val.icon}</div> */}
                      <div className="content">
                        <h3 className="title">{subcategory.name}</h3>
                        <p>{subcategory.description}</p>
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Categories;
