import React, { Component, Fragment } from 'react';
import PageHelmet from "../component/common/Helmet";
import { FiHeadphones , FiMail , FiMapPin } from "react-icons/fi";
import ContactTwo from "../elements/contact/ContactTwo";
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp } from "react-icons/fi";
import Header from '../component/header/HeaderFive';


class Contact extends Component {
	constructor(props) {
    super(props);
    this.agency = props.agency;
    this.center = {
      lng: this.agency.geopoint.coordinates[0],
      lat: this.agency.geopoint.coordinates[1],
    };
	}
	static defaultProps = {
		// center: {
		// 	lat: 31.9539,
		// 	lng: 35.9106,
		// },
		zoom: 15,
	};

	render() {
		return (
      <Fragment>
        <PageHelmet pageTitle="Contact" />
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* <Header headertransparent="header--transparent" colorblack="color--black" logoname="logo.png" /> */}

        {/* Start Slider Area   */}
        {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" data-black-overlay="6">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100">
									<h2 className="title theme-gradient">Cntact With Us</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        {/* End Slider Area   */}

        {/* Start Breadcrump Area */}
        {/* <div className="rn-page-title-area pt--120 pb--190 bg_image bg_image--17" data-black-overlay="6">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<div className="rn-page-title text-center pt--100">
									<h2 className="title theme-gradient">Cntact With Us</h2>
									<p>Contrary to popular belief, Lorem Ipsum is not simply random text. </p>
								</div>
							</div>
						</div>
					</div>
				</div> */}
        {/* End Breadcrump Area */}

        {/* Start Contact Page Area  */}
        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo agency={this.agency} />
        </div>
        {/* End Contact Page Area  */}

        {/* Start Contact Top Area  */}
        <div className="rn-contact-top-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                <div className="rn-address">
                  <div className="icon">
                    <FiHeadphones />
                  </div>
                  <div className="inner">
                    <h4 className="title">Contact With Phone Number</h4>
                    {this.agency.phoneNumbers.map((phoneNumber, i) => (
                      <p key={i}>
                        <a href={'tel:' + phoneNumber.value}>
                          Tel: {phoneNumber.value}
                        </a>
                      </p>
                    ))}
                    {this.agency.fax.map((fax, i) => (
                      <p key={i}>
                        <a href={'fax:' + fax.value}>Fax: {fax.value}</a>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_mobile--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMail />
                  </div>
                  <div className="inner">
                    <h4 className="title">Email Address</h4>
                    {this.agency.emails.map((email, i) => (
                      <p key={i}>
                        <a href={'mailto:' + email.value}>{email.value}</a>
                      </p>
                    ))}
                  </div>
                </div>
              </div>
              {/* End Single Address  */}

              {/* Start Single Address  */}
              <div className="col-lg-4 col-md-6 col-sm-6 col-12 mt_md--50 mt_sm--50">
                <div className="rn-address">
                  <div className="icon">
                    <FiMapPin />
                  </div>
                  <div className="inner">
                    <h4 className="title">Location</h4>
                    <p>
                      {this.agency.address}
                      {/* 5678 Bangla Main Road, cities 580 <br /> GBnagla, example
                      54786 */}
                    </p>
                  </div>
                </div>
              </div>
              {/* End Single Address  */}
            </div>
          </div>
        </div>
        {/* End Contact Top Area  */}

        {/* Start Contact Map  */}
        {/* <div className="rn-contact-map-area position-relative">
          <div style={{ height: '650px', width: '100%' }}>
            <GoogleMapReact
              defaultCenter={this.center}
              defaultZoom={this.props.zoom}
            >
              <AnyReactComponent
                lat={this.center.lat}
                lng={this.center.lng}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div> */}
        {/* End Contact Map  */}

        {/* Start Brand Area */}
        {/* <div className="rn-brand-area brand-separation bg_color--5 ptb--120">
					<div className="container">
						<div className="row">
							<div className="col-lg-12">
								<BrandTwo />
							</div>
						</div>
					</div>
				</div> */}
        {/* End Brand Area */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
	}
}
export default Contact