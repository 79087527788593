import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import axios from 'axios';
import { Icon, Tooltip } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

function Marker({ text }) {
  return (
    <Tooltip title={text} placement="top">
      <Icon
        style={{
          color: 'red',
        }}
        fontSize="large"
        className="text-red"
      >
        place
      </Icon>
    </Tooltip>
  );
}
class ContactTwo extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
    this.center = {
      lng: this.agency.geopoint.coordinates[0],
      lat: this.agency.geopoint.coordinates[1],
    };
    this.state = {
      request: {},
      hasError: false,
      requestStatus: 'new',
    };
  }
  static defaultProps = {
    // center: {
    // 	lat: 31.9539,
    // 	lng: 35.9106,
    // },
    zoom: 15,
  };

  sendContactRequest() {
    if (this.state.request.name == null || this.state.request.name === '') {
      console.log('ssss', this.state);
      this.setState({
        ...this.state,
        hasError: true,
      });
      return;
    }
    if (this.state.request.phoneNumber == null || this.state.request.phoneNumber === '') {
      this.setState({
        ...this.state,
        hasError: true,
      });
      return;
    }
    this.setState({
      ...this.state,
      hasError: false,
      requestStatus: 'loading',
    });
    const request = axios.post(
      '/api/v1/public/contactRequests',
      this.state.request
    );
    request
      .then((response) => {
        if (response.data) {
          this.setState({ isOpen: false, request: {}, requestStatus: 'new' });
        } else {
          this.setState({
            ...this.state,
            requestStatus: 'new',
          });
        }
      })
      .catch((error) => {
        this.setState({
          ...this.state,
          requestStatus: 'new',
        });
      });
  }
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-2 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
                <p className="description">{this.agency.contactText}</p>
              </div>
              <div className="form-wrapper">
                {this.state.hasError && (
                  <div>
                    <Alert severity="warning" className="col-lg-12">
                      <AlertTitle>Warning</AlertTitle>
                      Please fill all required fields<strong>*</strong>
                    </Alert>
                    <br />
                  </div>
                )}
                <form>
                  <label htmlFor="name">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      value={this.state.request.name}
                      onChange={(e) => {
                        const oldRequest = this.state.request;
                        oldRequest.name = e.target.value;
                        this.setState({ request: oldRequest });
                      }}
                      placeholder="Name *"
                    />
                  </label>

                  <label htmlFor="phoneNumber">
                    <input
                      type="text"
                      name="phoneNumber"
                      id="phoneNumber"
                      value={this.state.request.phoneNumber}
                      onChange={(e) => {
                        const oldRequest = this.state.request;
                        oldRequest.phoneNumber = e.target.value;
                        this.setState({ request: oldRequest });
                      }}
                      placeholder="Phone Number *"
                    />
                  </label>

                  <label htmlFor="email">
                    <input
                      type="text"
                      name="email"
                      id="email"
                      value={this.state.request.email}
                      onChange={(e) => {
                        const oldRequest = this.state.request;
                        oldRequest.email = e.target.value;
                        this.setState({ request: oldRequest });
                      }}
                      placeholder="Email"
                    />
                  </label>

                  <label htmlFor="message">
                    <textarea
                      type="text"
                      id="message"
                      name="message"
                      value={this.state.request.message}
                      onChange={(e) => {
                        const oldRequest = this.state.request;
                        oldRequest.message = e.target.value;
                        this.setState({ request: oldRequest });
                      }}
                      placeholder="Your Message"
                    />
                  </label>
                  {/* <button
                    className="rn-button-style--2 btn-solid"
                    type="submit"
                    value="submit"
                    name="submit"
                    id="mc-embedded-subscribe"
                  >
                    Submit
                  </button> */}
                </form>
                {this.state.requestStatus === 'new' ? (
                  <div>
                    <button
                      className="rn-button-style--2 btn-solid"
                      type="botton"
                      value="submit"
                      name="submit"
                      onClick={() => {
                        this.sendContactRequest();
                      }}
                      id="mc-embedded-subscribe"
                    >
                      Submit
                    </button>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-2">
              <div
                className="mb_md--30 mb_sm--30"
                style={{ height: '600px', width: '100%' }}
              >
                <GoogleMapReact
                  bootstrapURLKeys={{
                    key: 'AIzaSyCwUuvZPGltgFg_5Lq-IfiUXGSmFiwG-_U',
                  }}
                  defaultCenter={this.center}
                  defaultZoom={this.props.zoom}
                >
                  <Marker
                    lat={this.center.lat}
                    lng={this.center.lng}
                    text="My Marker"
                  />
                </GoogleMapReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
