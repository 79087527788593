import axios from 'axios';
// React Required
import React, { Component, Fragment } from 'react';
import ReactDOM from 'react-dom';

// Create Import File
import './index.scss';

import FooterTwo from './component/footer/FooterTwo';
// Common Layout
// import Layout from "./component/common/App";

// Home layout
import CorporateBusiness from './lama/Home';

// Element Layout
import About from './elements/About';
import Contact from './elements/Contact';
import Error404 from './elements/error404';

// Blocks Layout
import Categories from './lama/Categories';
import Subcategories from './lama/Subcategories';
import Posts from './lama/Posts';
import Achievements from './lama/Achievements';

import { BrowserRouter, Switch, Route } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';

class Root extends Component {
  constructor(props) {
    super(props);
    this.agency = props.agency;
  }

  render() {
    return (
      <Fragment>
        <BrowserRouter basename={'/'}>
          <Switch>
            <Route
              exact
              path="/"
              component={(props) => (
                <CorporateBusiness {...props} agency={this.agency} />
              )}
            />
            <Route
              exact
              path="/contact"
              component={(props) => <Contact {...props} agency={this.agency} />}
              agency={this.agency}
            />
            <Route
              exact
              path="/news"
              component={(props) => <Posts {...props} agency={this.agency} />}
              agency={this.agency}
            />
            <Route
              exact
              path="/about"
              component={(props) => <About {...props} agency={this.agency} />}
              agency={this.agency}
            />
            <Route
              exact
              path="/achievements"
              component={(props) => (
                <Achievements {...props} agency={this.agency} />
              )}
              agency={this.agency}
            />
            <Route
              path="/categories/:categoryId"
              component={(props) => (
                <Categories
                  key={props.match.params.categoryId}
                  {...props}
                  agency={this.agency}
                />
              )}
              agency={this.agency}
            />
            <Route
              path="/subcategories/:subcategoryId"
              component={(props) => (
                <Subcategories
                  key={props.match.params.subcategoryId}
                  {...props}
                  agency={this.agency}
                />
              )}
              agency={this.agency}
            />
            {/* <Route
              path="/products/:productId"
              component={(props) => (
                <Products {...props} agency={this.agency} />
              )}
              agency={this.agency}
            /> */}
            <Route
              component={(props) => (
                <Error404 {...props} agency={this.agency} />
              )}
            />
          </Switch>
        </BrowserRouter>
        {/* Start Footer Style  */}
        <FooterTwo agency={this.agency} />
        {/* End Footer Style  */}
      </Fragment>
    );
  }
}

const getAgency = async () => {
  // axios.defaults.baseURL = 'http://localhost:1337';
  axios.defaults.baseURL = 'https://lama-website.herokuapp.com';
  try {
    const response = await axios.get(
      '/api/v1/public/agencies'
    );
    return response.data;
  } catch (error) {
    console.log('error', error);
    return null;
  }
};

const start = async () => {
  const temp = await getAgency();

  ReactDOM.render(<Root agency={temp} />, document.getElementById('root'));
  serviceWorker.register();
};

start();
