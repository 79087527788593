import axios from 'axios';
import React, { Component, Fragment } from 'react';
import ScrollToTop from 'react-scroll-up';
import Header from '../component/header/HeaderFive';
import Helmet from '../component/common/Helmet';
import { FiChevronUp } from 'react-icons/fi';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';

class Subcategories extends Component {
  constructor(props) {
    super(props);
    this.subcategoryId = this.props.match.params.subcategoryId;
    this.subcategoryName = new URLSearchParams(this.props.location.search).get(
      'subcategoryName'
    );
    this.categoryName = new URLSearchParams(this.props.location.search).get(
      'categoryName'
    );
    this.subcategory = {
      name: this.subcategoryName,
    };
    this.products = [];
    this.agency = props.agency;
    this.state = {
      hasError: false,
      isOpen: false,
      isSaveDialogOpen: false,
      request: {},
    };
    this.openModal = this.openModal.bind(this);
    this.getSubcategory();
    this.getCategory();
  }

  sendContactRequest() {
    if (this.state.request.name == null || this.state.request.name === '') {
      this.setState({
        ...this.state,
        hasError: true,
      });
      return;
    }
    if (this.state.request.phoneNumber == null || this.state.request.phoneNumber === '') {
      this.setState({
        ...this.state,
        hasError: true,
      });
      return;
    }
    this.setState({
      ...this.state,
      requestStatus: 'loading',
      hasError: false,
    });
    const request = axios.post('/api/v1/public/contactRequests', this.state.request);
    request.then((response) => {
      if (response.data) {
        this.setState({ isOpen: false, request: {}, requestStatus: 'new' });
      } else {
        this.setState({
          ...this.state,
          requestStatus: 'new',
        });
      }
    }).catch((error) => {
      this.setState({
        ...this.state,
        requestStatus: 'new',
      });
    });
  }

  getSubcategory() {
    const request = axios.get(
      `/api/v1/public/subcategories/${this.subcategoryId}`
    );
    request.then((response) => {
      if (response.data) {
        this.subcategory = response.data;
        this.setState(this.state);
        this.getProducts('subcategoryId');
      }
    });
  }

  getCategory() {
    const request = axios.get(
      `/api/v1/public/categories/${this.subcategoryId}`
    );
    request.then((response) => {
      if (response.data) {
        this.subcategory = response.data;
        this.setState(this.state);
        this.getProducts('categoryId');
      }
    });
  }

  getProducts(key) {
    const request = axios.get(
      `/api/v1/public/products?${key}=${this.subcategoryId}`
    );
    request.then((response) => {
      this.products = response.data.results;
      this.setState(this.state);
    });
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <Fragment>
        <Helmet pageTitle={this.agency.name} />

        {/* Start Header Area  */}
        <Header
          headerPosition="header--static logoresize"
          logo="all-dark"
          color="color-black"
        />
        {/* End Header Area  */}

        {/* Start Team Area  */}
        <div className="home-demo-area bg_color--1 ptb--120">
          <div className="wrapper plr--120">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h2 className="theme-gradient">{this.subcategory.name}</h2>
                  <p>
                    {this.subcategory.description}
                    {/* Choose one of styles or cutomize easily your site following
                    your ideas. <br /> More demos are coming soon. */}
                    {this.subcategory.brand ? (
                      <ul
                        className="brand-style-2"
                        style={{ justifyContent: 'left' }}
                      >
                        <li style={{ paddingBottom: '0' }}>
                          <img
                            // src="https://locally-api.s3.amazonaws.com/full/180_1588093569656_blob"
                            src={this.subcategory.brand.imageUrl}
                            alt={this.subcategory.brand.name}
                          />
                        </li>
                      </ul>
                    ) : (
                      ''
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              {this.products.map((value, index) => (
                // Start Single Demo
                <div key={index} className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div
                    className="single-demo"
                    onClick={() => {
                      this.setState({
                        isOpen: true,
                        product: value,
                        request: {
                          product: value._id,
                        },
                        requestStatus: 'new',
                      });
                    }}
                  >
                    <div rel="noopener noreferrer" className="product">
                      {/* href={`/products/${value._id}?productName=${value.name}`} */}
                      <img src={value.imageUrl} alt="Creative Agency Images" />
                      <h3 className="title">{value.name}</h3>
                      <p className="info" style={{ whiteSpace: 'pre-line' }}>
                        {value.description}
                      </p>
                      <div className="service-btn">
                        <button className="btn-transparent rn-btn-dark">
                          <span className="text">Enquire</span>
                        </button>
                      </div>

                      {value.label ? (
                        <div className="label-new">
                          <span>{value.label}</span>
                        </div>
                      ) : (
                        ''
                      )}
                      {value.brand ? (
                        <div className="label-brand">
                          {/* <span>{value.brand.name}</span> */}
                          {value.brand ? (
                            value.brand.imageUrl ? (
                              <img
                                src={value.brand.imageUrl}
                                alt={value.brand.name}
                              />
                            ) : (
                              <span>{value.brand.name}</span>
                            )
                            ):''
                          }
                        </div>
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                </div>
                // End Single Demo
              ))}
            </div>
          </div>
        </div>
        {/* End Team Area  */}

        {/* Start Product */}
        <Dialog
          onClose={() => {
            this.setState({
              isOpen: false,
              request: {},
              requestStatus: 'new',
              hasError: false,
            });
          }}
          open={this.state.isOpen}
          fullWidth
          component="form"
        >
          <DialogTitle>
            <p className="info">*{this.agency.productContactText}</p>
            <strong>{this.state.product ? this.state.product.name : ''}</strong>
          </DialogTitle>
          <DialogContent classes={{ root: 'p-16 pb-0 sm:p-24 sm:pb-0' }}>
            <div className="form-wrapper">
              <div className="rn-contact-page">
                <div className="contact-form--1">
                  <div className="container">
                    <div className="row row--35 align-items-start">
                      <div className="col-lg-12 order-2 order-lg-1">
                        <div className="form-wrapper">
                          {this.state.hasError && (
                            <Alert severity="warning" className="col-lg-12">
                              <AlertTitle>Warning</AlertTitle>
                              Please fill all required fields<strong>*</strong>
                            </Alert>
                          )}

                          <form>
                            <FormControlLabel
                              className="mt-8 mb-16"
                              label="Specifications"
                              control={
                                <Switch
                                  disabled={
                                    this.state.requestStatus === 'loading'
                                  }
                                  color="primary"
                                  id="specifications"
                                  name="specifications"
                                  checked={this.state.request.specifications}
                                  onChange={(e) => {
                                    const oldRequest = this.state.request;
                                    oldRequest.specifications =
                                      e.target.checked;
                                    this.setState({ request: oldRequest });
                                  }}
                                />
                              }
                            />
                            <FormControlLabel
                              className="mt-8 mb-16"
                              label="Catalogue"
                              control={
                                <Switch
                                  disabled={
                                    this.state.requestStatus === 'loading'
                                  }
                                  color="primary"
                                  checked={this.state.request.catalogue}
                                  onChange={(e) => {
                                    const oldRequest = this.state.request;
                                    oldRequest.catalogue = e.target.checked;
                                    this.setState({ request: oldRequest });
                                  }}
                                  id="catalogue"
                                  name="catalogue"
                                />
                              }
                            />
                            <FormControlLabel
                              className="mt-8 mb-16"
                              label="Price"
                              control={
                                <Switch
                                  disabled={
                                    this.state.requestStatus === 'loading'
                                  }
                                  color="primary"
                                  checked={this.state.request.price}
                                  onChange={(e) => {
                                    const oldRequest = this.state.request;
                                    oldRequest.price = e.target.checked;
                                    this.setState({ request: oldRequest });
                                  }}
                                  id="price"
                                  name="price"
                                />
                              }
                            />
                            <FormControlLabel
                              className="mt-8 mb-16"
                              label="Visit Sales Representative"
                              control={
                                <Switch
                                  disabled={
                                    this.state.requestStatus === 'loading'
                                  }
                                  color="primary"
                                  checked={this.state.request.visit}
                                  onChange={(e) => {
                                    const oldRequest = this.state.request;
                                    oldRequest.price = e.target.checked;
                                    this.setState({ request: oldRequest });
                                  }}
                                  id="visit"
                                  name="visit"
                                />
                              }
                            />
                            <br />
                            <label htmlFor="name">
                              <input
                                disabled={
                                  this.state.requestStatus === 'loading'
                                }
                                type="text"
                                name="name"
                                id="name"
                                value={this.state.request.name}
                                onChange={(e) => {
                                  const oldRequest = this.state.request;
                                  oldRequest.name = e.target.value;
                                  this.setState({ request: oldRequest });
                                }}
                                placeholder="Name *"
                              />
                            </label>
                            <label htmlFor="phoneNumber">
                              <input
                                disabled={
                                  this.state.requestStatus === 'loading'
                                }
                                type="text"
                                name="phoneNumber"
                                id="phoneNumber"
                                value={this.state.request.phoneNumber}
                                onChange={(e) => {
                                  const oldRequest = this.state.request;
                                  oldRequest.phoneNumber = e.target.value;
                                  this.setState({ request: oldRequest });
                                }}
                                placeholder="Phone Number *"
                              />
                            </label>
                            <label htmlFor="email">
                              <input
                                disabled={
                                  this.state.requestStatus === 'loading'
                                }
                                type="text"
                                name="email"
                                id="email"
                                value={this.state.request.email}
                                onChange={(e) => {
                                  const oldRequest = this.state.request;
                                  oldRequest.email = e.target.value;
                                  this.setState({ request: oldRequest });
                                }}
                                placeholder="Email "
                              />
                            </label>
                            <label htmlFor="address">
                              <input
                                disabled={
                                  this.state.requestStatus === 'loading'
                                }
                                type="text"
                                name="address"
                                id="address"
                                value={this.state.request.address}
                                onChange={(e) => {
                                  const oldRequest = this.state.request;
                                  oldRequest.address = e.target.value;
                                  this.setState({ request: oldRequest });
                                }}
                                placeholder="Address "
                              />
                            </label>
                            <label htmlFor="message">
                              <textarea
                                disabled={
                                  this.state.requestStatus === 'loading'
                                }
                                id="message"
                                name="message"
                                value={this.state.request.message}
                                onChange={(e) => {
                                  const oldRequest = this.state.request;
                                  oldRequest.message = e.target.value;
                                  this.setState({ request: oldRequest });
                                }}
                                placeholder="Message"
                              />
                            </label>
                          </form>
                          {this.state.requestStatus === 'new' ? (
                            <div className="text-center">
                              <button
                                className="rn-button-style--2 btn-solid"
                                type="botton"
                                value="submit"
                                name="submit"
                                onClick={() => {
                                  this.sendContactRequest();
                                }}
                                id="mc-embedded-subscribe"
                              >
                                Submit
                              </button>
                            </div>
                          ) : (
                            ''
                          )}

                          {this.state.requestStatus === 'loading' ? (
                            <div className="text-center">Sending...</div>
                          ) : (
                            ''
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        {/* End Product */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}
      </Fragment>
    );
  }
}
export default Subcategories;
