import axios from 'axios';
import React ,{ Component }from "react";


class ServiceThree extends Component {
	constructor() {
		super();
		this.categories = [];
		this.state = {
		};
		this.getCategories();
	}
	getCategories() {
		const request = axios.get(
      '/api/v1/public/categories'
    );
		request.then((response) => {
			this.categories = response.data.results;
			this.setState(this.state);
		});
	}
	render() {
		const { column } = this.props;

		return (
      <React.Fragment>
        <div className="row justify-content-md-center">
          {this.categories.map((val, i) =>
            val.hasSub ? (
              <div className={`${column}`} key={i}>
                <a href={val.actionUrl ?? `/categories/${val._id}?categoryName=${val.name}`}>
                  <div className="service service__style--2">
                    <div className="icon"></div>
                    <div className="content">
                      <h3 className="title">{val.name}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            ) : (
              <div className={`${column}`} key={i}>
                <a href={val.actionUrl ?? `/subcategories/${val._id}?categoryName=${val.name}`}>
                  <div className="service service__style--2">
                    <div className="icon"></div>
                    <div className="content">
                      <h3 className="title">{val.name}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                </a>
              </div>
            )
          )}
        </div>
      </React.Fragment>
    );
	}
}
export default ServiceThree;
